<template>
  <div class="main">
    <breadcrumb>
      <breadcrumb-item>{{$t('OrderManagement')}}</breadcrumb-item>
      <breadcrumb-item>{{$t('GroupNumber')}}：{{filterData.groupNumber}}</breadcrumb-item>
    </breadcrumb>
    <div class="filter cl">
      <div class="item cl">
        <div class="label">{{$t('GroupNumber')}}：{{orderGroupNumber.groupNumber}}</div>
      </div>
      <div class="item cl">
        <div class="label">{{$t("Number of applicants")}}（{{$t('big')}}+{{$t('small')}}+{{$t('body')}}）
          ：{{orderGroupNumber.adultNumber}}+{{orderGroupNumber.childNumber+orderGroupNumber.notChildNumber}}+{{orderGroupNumber.babyNumber}}
          ={{orderGroupNumber.adultNumber+orderGroupNumber.childNumber+orderGroupNumber.notChildNumber+orderGroupNumber.babyNumber}}</div>
      </div>
      <div class="item cl">
        <div class="label">{{$t('PriceType')}}：
          <span v-if="orderGroupNumber.priceType==0">{{$t('PriceTypes')[0]}}</span>
          <span v-else>{{$t('PriceTypes')[1]}}</span>
        </div>
      </div>
      <div class="item cl">
        <div class="label">{{$t('ProductNumber')}}： {{orderGroupNumber.productNo}}</div>
      </div>
      <div class="item cl">
        <div class="label">{{$t('MaximumNumberOfPeople')}}：{{orderGroupNumber.limitPeopleNumber}}</div>
      </div>
      <div class="item cl">
        <div class="label">{{$t('Currency')}}： {{orderGroupNumber.billingType}}</div>
      </div>
      <div class="item cl">
        <div class="label">{{$t('ProductName')}}：{{orderGroupNumber.productTitle}}</div>
        <div class="fl">
        </div>
      </div>
      <div class="item cl">
        <div class="label">{{$t('GroupOfPeople')}}：{{orderGroupNumber.groupPeopleNumber}}</div>
        <div class="fl">
        </div>
      </div>
      <div class="item cl" v-if="isDay===0">
        <div class="label">{{$t('The number of rooms')}}： {{orderGroupNumber.roomsNumber}}</div>
      </div>
      <div class="item cl">
        <div class="label">{{$t('Route')}}：{{orderGroupNumber.line}}</div>
      </div>
      <div class="item cl">
        <div class="label">{{$t('Date of departure')}}：{{orderGroupNumber.priceDate}}</div>
      </div>
      <div class="item cl">
        <div class="label">{{$t('Group status')}}：{{orderGroupNumber.groupStatus}}</div>
      </div>
      <div class="item cl">
        <div class="label">{{$t('Deadline for join')}}：{{orderGroupNumber.overDate}}</div>
      </div>
    </div>
    <div class="filter cl">
        {{$t('TradingStatus')}}：
        <el-select class="select" clearable size="small" :placeholder="$t('All')" v-model="filterData.transationStatus" v-on:change="getOrderGroupNumberDetail(1)">
          <el-option v-for="(item,key) of $t('TransationStatus')"
                     :key="key"
                     :label="item"
                     :value="key">
          </el-option>
        </el-select>

        {{$t('Order status')}}：
        <el-select class="select" clearable size="small" :placeholder="$t('All')" v-model="filterData.orderStatus" v-on:change="getOrderGroupNumberDetail(1)">
          <el-option v-for="(item,key) of $t('OrderStatus')"
                     :key="key"
                     :label="item"
                     :value="key">
          </el-option>
        </el-select>

      <button class="button button-primary small m-r" type="button" @click="switchPage1()">{{$t('All order materials')}}</button>
      <button class="button button-primary small m-r" type="button" @click="switchPage2()">{{$t('All passenger information')}}</button>
      <button class="button button-primary small m-r" type="button" @click="exportExcel">{{$t('Export excel')}}</button>
    </div>
    <div v-if="page1">
      <div class="table border">
        <div style="overflow-x:auto">
          <div style="width:1800px">
            <table>
              <thead>
              <tr>
                <th>{{$t('Operating')}}</th>
                <th>NO.</th>
                <th>{{$t('TradingStatus')}}</th> <!--交易狀態-->
                <th>{{$t('Order status')}}</th> <!--訂單狀態-->
                <th>{{$t('Order number')}}</th> <!--訂單編號-->
                <th>{{$t('CreationTime')}}</th> <!--创建时间-->
                <th>{{$t('GroupNumber')}}</th> <!--团号-->
                <th>{{$t('ProductNumber')}}</th> <!--產品編號-->
                <th width="250">{{$t('ProductName')}}</th> <!--產品名稱-->
                <th>{{$t('Route')}}</th> <!--路線-->
                <th>{{$t('Date of departure')}}</th> <!--出團日期-->
                <th>{{$t('Group status')}}</th> <!--成團狀態-->
                <th>{{$t('Order Source')}}</th> <!--訂單來源-->
                <th>{{$t('Number of people')}}</th> <!--人數-->
                <th>{{$t('NumberOfRooms')}}</th> <!--房間數目-->
                <th>{{$t('Amount')}}</th> <!--金额-->
                <th>{{$t('Payment method')}}</th> <!--支付方式-->
                <th>{{$t('CancellationTime')}}</th> <!--取消時間-->
                <th>{{$t('CancellationType')}}</th> <!--取消類型-->
                <th>{{$t('RefundStatus')}}</th> <!--退款狀態-->
                <th>{{$t('RefundAmount')}}</th> <!--退款金額-->
                <th>{{$t('Billing status')}}</th> <!--結算狀態-->
                <th>{{$t('Reconciliation Status')}}</th> <!--對帳狀態-->
                <!--<th>{{$t('Notification Status')}}</th>--><!--通知狀態-->
              </tr>
              </thead>
              <tbody>
              <tr v-for="(item,index) of list" :key="item.orderId">
                <td class="nowrap">
                  <router-link class="btn-item" target="_blank" :to="{name:'AdminOrderDetail',query:{id:item.orderId}}">
                    <img src="../../../assets/images/icon/review.png"/>
                  </router-link>
                  <!--<a class="btn-item" href="javascript:;">
                    <img src="../../../assets/images/icon/pen.png"/>
                  </a>
                  <a class="btn-item" href="javascript:;">
                    <img src="../../../assets/images/icon/unlock.png"/>
                  </a>-->
                </td>
                <td class="nowrap">{{index+1}}</td>

                <td><span :class="{fc1:item.transationStatus===3}">{{$t('TransationStatus['+item.transationStatus+']')}}</span></td> <!--交易狀態-->
                <td>{{$t('OrderStatus')[item.orderStatus]}}</td> <!--訂單狀態-->
                <td><router-link class="btn-item" target="_blank" :to="{name:'AdminOrderDetail',query:{id:item.orderId}}">
                  {{item.orderNo}}
                </router-link></td> <!--訂單編號-->
                <td>{{item.addDate|formatDate('yyyy.MM.dd hh:mm')}}</td> <!--创建时间-->
                <td>{{item.groupNumber}}</td> <!--团号-->
                <td class="nowrap">{{item.productNo}}</td> <!--產品編號-->
                <td>{{item.productTitle}}</td> <!--產品名稱-->
                <td class="nowrap">{{$t('RoutesDays',[item.lineName,item.days||item.lates])}}</td> <!--路線-->
                <td class="nowrap">{{item.priceDate}}</td> <!--出團日期-->
                <td>
                  <!--<i class="status-circle"></i><span class="vam">已成团</span>-->
                  {{item.groupStatus}}
                </td> <!--成團狀態-->
                <td>{{item.orderSource|dictName('orderSource')}}</td> <!--訂單來源-->
                <td>{{item.orderPeopleNumber}}</td> <!--人數-->
                <td>{{item.roomsNumber}}</td> <!--房間數目-->
                <td class="nowrap">{{item.billingType}} {{item.totalMoney||item.totalPrice}}</td> <!--金额-->
                <td class="nowrap">{{item.payPlatform|dictName('payPlatform')}}</td>  <!--支付方式-->
                <td></td> <!--取消時間-->
                <td></td> <!--取消類型-->
                <td></td> <!--退款狀態-->
                <td></td> <!--退款金額-->
                <td>{{$t("SettlementStatus")[item.settlementStatus]}}</td> <!--結算狀態-->
                <td>{{$t("ReconciliationStatus")[item.reconciliationStatus]}}</td> <!--對帳狀態-->

                <!--<td class="nowrap is-link" @click="openOrderListDetail(item.orderId)">{{item.productNo}}</td>-->
                <!--<td>{{item.isGroupNotice==='Y'?$t('GroupNoticeHasBeenSent'):''}}</td>--><!--成團通知已發送-->
              </tr>
              </tbody>
            </table>
          </div>
        </div>
        <empty v-if="isEmpty" :title="$t('NoData')"/>
        <el-pagination class="pages txt-r"
                       layout="prev, pager, next"
                       :page-count="totalPages"
                       @current-change="getOrderGroupNumberDetail"
                       hide-on-single-page>
        </el-pagination>
      </div>
    </div>
    <div v-if="page2">
      <tabs v-model="tabNumber">
        <tab-pane :label="$t('Traveler information')" name="1" v-on:@click="changeTabNumber(1)"></tab-pane>
        <tab-pane :label="$t('Contact information')" name="2" v-on:@click="changeTabNumber(2)"></tab-pane>
        <tab-pane :label="$t('Emergency Contact')[0]" name="3" v-on:@click="changeTabNumber(3)"></tab-pane>
        <tab-pane :label="$t('Demand notes')" name="4" v-on:@click="changeTabNumber(4)"></tab-pane>
      </tabs>
      <!--旅客资料-->
      <div class="table border" v-if="tabNumber==1">
            <table>
              <thead>
              <tr>
                <th>NO.</th>
                <th>{{$t('Order number')}}</th><!--旅客類型-->
                <th>{{$t('TravellerType')}}</th><!--旅客類型-->
                <th>{{$t('Appellation')}}</th><!--稱謂-->
                <th>{{$t('English name')}}</th>
                <th>{{$t('Gender')}}</th>
                <th>{{$t('Certificate Type')}}</th>
                <th>{{$t('Certificate of Issue')}}</th>
                <th>{{$t('Certificate Number')}}</th>
                <th>{{$t('Certificate Validity Period')}}</th>
                <th>{{$t('DateOfBirth')}}</th>
                <th>{{$t('Mobile Number')}}</th>
                <th v-if="orderInfo.roomsNumber%1 === 0.5&&isDay===0">{{$t('FightPassenger')}}</th>
              </tr>
              </thead>
              <tbody>
              <tr v-for="(item,index) of passengerInfo" :key="item.id">
                <td class="nowrap">{{index+1}}</td>
                <td><router-link class="btn-item" target="_blank" :to="{name:'AdminOrderDetail',query:{id:item.orderId}}">
                  {{item.orderNo}}
                </router-link></td>
                <td>{{(item.travelerType+item.isOccupyBed)|dictName('travelerType')}}</td>
                <td>{{item.isCalled|dictName(appellationOptions)}}</td>
                <td>{{item.englishSurname}}/{{item.englishName}}</td>
                <td>{{item.genderType|dictName('gender')}}</td>
                <td>{{item.paperworkType|dictName(certificateTypeOptions)}}</td>
                <td>{{item.issuingCountry|dictName(countryOptions)}}</td>
                <td>{{item.idNumber}}</td>
                <td>{{item.effectiveDate}}</td>
                <td>{{item.birthday}}</td>
                <td class="nowrap">({{item.phoneType}}) {{item.userPhone}}</td>
                <td v-if="orderInfo.roomsNumber%1 === 0.5&&isDay===0">{{item.isFight==='Y'?$t('SharedRoom'):''}}</td>
              </tr>
              </tbody>
            </table>

        <empty v-if="isEmpty" :title="$t('NoData')"/>
        <el-pagination class="pages txt-r"
                       layout="prev, pager, next"
                       :page-count="totalPages"
                       @current-change="getOrderGroupNumberDetail"
                       hide-on-single-page>
        </el-pagination>
      </div>
      <!--联系人资料-->
      <div class="table border" v-if="tabNumber==2">

            <table>
              <thead>
              <tr>
                <th>NO.</th>
                <th>{{$t('Order number')}}</th>
                <th>{{$t('Appellation')}}</th>
                <th>{{$t('Contact Name')}}({{$t('English')}})</th>
                <th>{{$t('Contact Name')}}({{$t('Chinese')}})</th>
                <th>{{$t('Contact Number')}}</th>
                <th>{{$t('Email')}}</th>
              </tr>
              </thead>
              <tbody>
              <tr v-for="(item,index) of contactInformation" :key="item.id">
                <td class="nowrap">{{index+1}}</td>
                <td><router-link class="btn-item" target="_blank" :to="{name:'AdminOrderDetail',query:{id:item.orderId}}">
                  {{item.orderNo}}
                </router-link></td>
                <td>{{item.isCalled|dictName(appellationOptions)}}</td>
                <td>{{item.englishSurname}}/{{item.englishName}}</td>
                <td>{{item.chineseSurname}}{{item.chineseName}}</td>
                <td>({{item.phoneType}}) {{item.userPhone}}</td>
                <td>{{item.userEmail}}</td>
              </tr>
              </tbody>
            </table>

        <empty v-if="isEmpty" :title="$t('NoData')"/>
        <el-pagination class="pages txt-r"
                       layout="prev, pager, next"
                       :page-count="totalPages"
                       @current-change="getOrderGroupNumberDetail"
                       hide-on-single-page>
        </el-pagination>
      </div>
      <!--紧急联系人-->
      <div class="table border" v-if="tabNumber==3">

            <table>
              <thead>
              <tr>
                <th>NO.</th>
                <th>{{$t('Order number')}}</th>
                <th>{{$t('Name')}}({{$t('English')}})</th>
                <th>{{$t('Name')}}({{$t('Chinese')}})</th>
                <th>{{$t('Mobile Number')}}</th>
              </tr>
              </thead>
              <tbody>
              <tr v-for="(item,index) of emergencyContact" :key="item.id">
                <td class="nowrap">{{index+1}}</td>
                <td><router-link class="btn-item" target="_blank" :to="{name:'AdminOrderDetail',query:{id:item.orderId}}">
                  {{item.orderNo}}
                </router-link></td>
                <td>{{item.englishSurname}}/{{item.englishName}}</td>
                <td>{{item.chineseSurname}}{{item.chineseName}}</td>
                <td>({{item.phoneType}}) {{item.userPhone}}</td>
              </tr>
              </tbody>
            </table>

    <empty v-if="isEmpty" :title="$t('NoData')"/>
    <el-pagination class="pages txt-r"
                   layout="prev, pager, next"
                   :page-count="totalPages"
                   @current-change="getOrderGroupNumberDetail"
                   hide-on-single-page>
    </el-pagination>
      </div>

      <!--需求备注-->
      <div class="table border" v-if="tabNumber==4">

            <table>
              <thead>
              <tr>
                <th>NO.</th>
                <th>{{$t('Order number')}}</th>
                <th>{{$t('Receipt up')}}</th>
                <th>{{$t('More demand')}}</th>
              </tr>
              </thead>
              <tbody>
              <tr v-for="(item,index) of memoList" :key="item.id">
                <td class="nowrap">{{index+1}}</td>
                <td><router-link class="btn-item" target="_blank" :to="{name:'AdminOrderDetail',query:{id:item.orderId}}">
                  {{item.orderNo}}
                </router-link></td>
                <td>{{item.gainGround}}</td>
                <td>{{item.otherDemands}}</td>
              </tr>
              </tbody>
            </table>

  <empty v-if="isEmpty" :title="$t('NoData')"/>
  <el-pagination class="pages txt-r"
                 layout="prev, pager, next"
                 :page-count="totalPages"
                 @current-change="getOrderGroupNumberDetail"
                 hide-on-single-page>
  </el-pagination>
      </div>

    </div>
  </div>
</template>
<script>
  import Breadcrumb from '../../../components/admin/Breadcrumb';
  import BreadcrumbItem from '../../../components/BreadcrumbItem';
  import Tabs from '../../../components/Tabs/Tabs';
  import TabPane from '../../../components/Tabs/TabPane';
  import Empty from '../../../components/Empty';
  import {formatDate, scrollToY} from '../../../utils';
  import {getOrderGroupNumberDetail, getOrderGroupNumberInfo, adminGetProduct, exportExcel} from '../../../api/admin-order';
  import order from '../../../mixins/order';
  import {getDictList} from '../../../api/admin-common';
  import {dictName, orderStatus, reconciliationStatus, settlementStatus, transationStatus} from '@/filters';
  export default {
    name: 'AdminOrderList',
    mixins: [order],
    data() {
      return {

        activeName: 'all',
        statusOptions: [
          {value: '1', label: '上线'},
          {value: '2', label: '暂停'}
        ],
        page1: true,
        page2: false,
        tabNumber: 1,
        status: '',
        filterData: {
          isMy: 'N',
          orderNo: '',
          contactName: '',
          productNo: '',
          contactPhone: '',
          orderStatus: '',
          productTitle: '',
          groupNumber: '',
          transationStatus: ''
        },
        orderGroupNumber: {},
        orderInfo: {},
        passengerInfo: [],
        contactInformation: [],
        emergencyContact: [],
        memoList: [],
        list: [],
        totalPages: 1,
        isEmpty: false,
        productId: '',
        isDay: 0
      };
    },
    components: {Empty, Breadcrumb, BreadcrumbItem, Tabs, TabPane},
    created() {
      this.filterData.isMy = this.$route.query.type === 'my' ? 'Y' : 'N';
      this.filterData.groupNumber = this.$route.query.groupNumber;
      this.getOrderGroupNumberDetail();
      this.getOrderGroupNumberInfo();
      this.getDicts();
    },
    methods: {
      openOrderListDetail(id) {
        const routeUrl = this.$router.resolve({name: 'AdminOrderListDetail', params: {id}});
        window.open(routeUrl.href, '_blank');
      },
      // 获取团号基本信息
      getOrderGroupNumberInfo() {
        getOrderGroupNumberInfo(this.filterData).then(res => {
          const {value} = res;
          this.orderGroupNumber = value.orderGroupNumber || {};
        });
      },
      // 获取产品基本信息
      adminGetProduct() {
        adminGetProduct(this.productId).then(res => {
          this.isDay = res.value.isDay;
        });
      },
      // 获取订单列表
      getOrderGroupNumberDetail(page = 1) {
        const params = {page, perPage: 5};
        getOrderGroupNumberDetail(params, this.filterData).then(res => {
          const {value} = res;
          console.log(value);
          let order = value.list[0].order || {};
          this.memoList = value.list[0].memoList || [];
          const list = value.list[0].list || [];
          this.list = list;
          this.productId = this.list[0].productId;
          this.orderInfo = this.formatOrder(order);
          this.passengerInfo = value.list[0].travelers || [];
          const contacts = value.list[0].contacts || [];
          this.contactInformation = this.createContactInformationList(contacts);
          this.emergencyContact = this.createContactInformationList(contacts, 'urgent');
          this.totalPages = value.totalPages;
          this.isEmpty = params.page === 1 && !this.list.length;
          this.adminGetProduct();
          this.$nextTick(() => {
            scrollToY();
          });
        });
      },
      // 清除过滤参数
      clearFilterData() {
        let filterData = this.$options.data().filterData;
        filterData.isMy = this.$route.query.type === 'my' ? 'Y' : 'N';
        this.filterData = filterData;
        this.getOrderGroupNumberDetail();
      },
      switchPage1() {
        this.page1 = true;
        this.page2 = false;
      },
      switchPage2() {
        this.page2 = true;
        this.page1 = false;
      },
      // 導出excel
      exportExcel() {
        require.ensure([], () => {
          const { exportJsonToExcel2 } = require('../../../utils/Export2Excel');
          // 有多少個sheet
          var th = ['訂單資料', '旅客資料', '聯絡人資料', '緊急聯絡人', '需求備註'];

          // 訂單資料表頭
          var listHeader = ['交易狀態', '訂單狀態', '訂單編號', '創建時間', '團號', '產品編號', '產品名稱', '路線', '出團日期', '成團狀態', '訂單來源', '人數', '房間數目', '金額', '支付方式', '結算狀態', '對帳狀態'];
          // 訂單資料變量
          var listVal = ['transationStatus', 'orderStatus', 'orderNo', 'addDate', 'groupNumber', 'productNo', 'productTitle', 'lineTitle', 'priceDate', 'groupStatus', 'orderSource', 'orderPeopleNumber', 'roomsNumber', 'money', 'payPlatform', 'settlementStatus', 'reconciliationStatus'];
          // 旅客資料表頭
          var travelersHeader = [];
          // 旅客資料變量
          var travelersVal = [];
          if (this.isDay === 0) {
            // 旅客資料表頭
            travelersHeader = ['訂單編號', '旅客類型', '稱謂', '英文名', '性別', '證件類型', '證件簽發地', '證件號碼', '證件有效期', '出生日期', '手提電話', '拼房旅客'];
            // 旅客資料變量
            travelersVal = ['orderNo', 'travelerType', 'isCalled', 'english', 'genderType', 'paperworkType', 'issuingCountry', 'idNumber', 'effectiveDate', 'birthday', 'phone', 'isFight'];
          } else {
            // 旅客資料表頭
            travelersHeader = ['訂單編號', '旅客類型', '稱謂', '英文名', '性別', '證件類型', '證件簽發地', '證件號碼', '證件有效期', '出生日期', '手提電話'];
            // 旅客資料變量
            travelersVal = ['orderNo', 'travelerType', 'isCalled', 'english', 'genderType', 'paperworkType', 'issuingCountry', 'idNumber', 'effectiveDate', 'birthday', 'phone'];
          }

          // 聯絡人表頭
          var contactInformationHeader = ['訂單編號', '稱謂', '姓名（英文）', '姓名（中文）', '手提電話', 'Email'];
          // 聯絡人變量
          var contactInformationVal = ['orderNo', 'isCalled', 'english', 'chinese', 'phone', 'userEmail'];

          // 緊急聯絡人表頭
          var emergencyContactHeader = ['訂單編號', '稱謂', '姓名（英文）', '姓名（中文）', '手提電話'];
          // 緊急聯絡人變量
          var emergencyContactVal = ['orderNo', 'isCalled', 'english', 'chinese', 'phone'];

          // 需求備註表頭
          var memoListHeader = ['訂單編號', '收據抬頭', '更多需求'];
          // 需求備註變量
          var memoListVal = ['orderNo', 'gainGround', 'otherDemands'];

          var filename = this.filterData.groupNumber + '團';
          exportExcel(this.filterData).then(res => {
            let {value} = res;
            const list = value[0].list || [];
            const contacts = value[0].contacts || [];
            const memoList = value[0].memoList || [];
            const contactInformation = this.createContactInformationList(contacts);
            const emergencyContact = this.createContactInformationList(contacts, 'urgent');
            const travelers = value[0].travelers || [];

            const listData = this.formatJson(listVal, list);
            listData.unshift(listHeader);

            const travelersData = this.formatJson(travelersVal, travelers);
            travelersData.unshift(travelersHeader);

            const contactInformationData = this.formatJson(contactInformationVal, contactInformation);
            contactInformationData.unshift(contactInformationHeader);

            const emergencyContactData = this.formatJson(emergencyContactVal, emergencyContact);
            emergencyContactData.unshift(emergencyContactHeader);

            const memoLisData = this.formatJson(memoListVal, memoList);
            memoLisData.unshift(memoListHeader);
            // 存全部數組
            const dataArray = [];
            dataArray.push(listData);
            dataArray.push(travelersData);
            dataArray.push(contactInformationData);
            dataArray.push(emergencyContactData);
            dataArray.push(memoLisData);

            console.log(dataArray);
            exportJsonToExcel2(th, dataArray, filename, th);
          });
        });
      },
      // 過濾
      formatJson(filterVal, jsonData) {
        return jsonData.map(v => filterVal.map(j => {
            var a = v[j];
            if (j === 'addDate') {
              a = formatDate(a, 'yyyy.MM.dd hh:mm');
            }
            if (j === 'transationStatus') {
              a = transationStatus(a);
            }
            if (j === 'orderStatus') {
              a = orderStatus(a);
            }
            if (j === 'settlementStatus') {
              a = settlementStatus(a);
            }
            if (j === 'reconciliationStatus') {
              a = reconciliationStatus(a);
            }
            if (j === 'travelerType') {
              a = dictName(a, 'travelerType');
            }
            if (j === 'isFight') {
              if (a === 'Y') {
                a = '拼房';
              } else {
                a = '';
              }
            }
            if (j === 'isCalled') {
              a = dictName(a, this.appellationOptions);
            }
            if (j === 'orderSource') {
              a = dictName(a, 'orderSource');
            }
            if (j === 'payPlatform') {
              a = dictName(a);
            }
            if (j === 'genderType') {
              a = dictName(a, 'gender');
            }
            if (j === 'paperworkType') {
              a = dictName(a, this.certificateTypeOptions);
            }
            if (j === 'issuingCountry') {
              a = dictName(a, this.countryOptions);
            }
            return a;
          }
          ));
      },
      changeTabNumber(number = 1) {
        this.tabNumber = number;
      },
      // 获取字典
      getDicts() {
        // 国家/证件签发地
        getDictList('country').then(res => {
          this.countryOptions = res.value || [];
        });

        // 证件类型
        getDictList('certificateType').then(res => {
          this.certificateTypeOptions = res.value || [];
        });

        // 获取称谓选项
        getDictList('appellative').then(res => {
          this.appellationOptions = res.value || [];
        });

        getDictList('phonePrefix').then(res => {
          this.areaCodeOptions = res.value;
        });
      }
    },
    watch: {
      $route: function (value) {
        this.clearFilterData();
      }
    }
  };
</script>
<style scoped lang="less">
  @import "../../../assets/style/table";
  .main{
    /deep/ .tab-nav{
      margin-top:6px;
      .item{
        width:auto;margin-right:32px;
        &:after{right:0;left:0;width:auto;}
        a{min-width:auto;}
      }
    }
    .status-circle{
      display:inline-block;width:6px;height:6px;margin-right:10px;vertical-align:middle;background-color:#00b680;border-radius:50%;
      &.orange{background-color:#ff9b00;}
      &.gray{background-color:#999;}
    }
  }
  .filter{
    margin-top:30px;
    .item{
      float:left;width:33.3333%;margin-bottom:24px;vertical-align:middle;
      .label{float:left;line-height:32px;}
    }
    .ipt{
      width:160px;height:32px;padding:0 10px;border:1px solid #d9d9d9;border-radius:4px;
      &:focus{border-color:#ff6f61;}
    }
    /deep/ .select{width:160px;vertical-align:top;}
  }

  .table.border{
  overflow-x: auto;
  }
  .fc1{color:#ff6f61;}

  .button.small{
    margin-left: 10px;
  }
</style>
